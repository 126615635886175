import axios from '@/utils/request'
let Api = {
	schoolTeacherAccountEdit: "/updateInfo",
	studentAccountEdit: "/student/updateInfo",
	passwordEdit: "/updatePassword",
	messageUnreadNum: "/siteMsg/noReadNum",
	messageUnreadClear: "/siteMsg/read/",
	messageList: "/siteMsg/list",
	messageDetail: "/siteMsg/",
	scanDetail: "/post/postQrCodeDetail",
	singInOut: "/post/signInOrOut",
	studentInfo: "/student/",
	deleteResume: "/student/deleteResume",
	deleteSchedule: "/student/deleteCurriculum",
}
// 学校老师信息编辑
export function schoolTeacherAccountEditMb(params) {
	return axios({
		method: 'post',
		url: Api.schoolTeacherAccountEdit,
		data: params
	})
}
// 学生信息编辑
export function studentAccountEditMb(params) {
	return axios({
		method: 'post',
		url: Api.studentAccountEdit,
		data: params
	})
}
// 修改密码
export function passwordEditMb(params) {
	return axios({
		method: 'post',
		url: Api.passwordEdit,
		data: params
	})
}
// 消息未读数量
export function messageUnreadNumMb(params) {
	return axios({
		method: 'get',
		url: Api.messageUnreadNum,
		params: params
	})
}
// 消息未读清空
export function messageUnreadClearMb(id, params) {
	return axios({
		method: 'get',
		url: Api.messageUnreadClear + id,
		params: params
	})
}
// 消息列表
export function messageListMb(params) {
	return axios({
		method: 'get',
		url: Api.messageList,
		params: params
	})
}
// 消息详情
export function messageDetailMb(id, params) {
	return axios({
		method: 'get',
		url: Api.messageDetail + id,
		params: params
	})
}
// 扫码签到详情
export function scanDetailMb(params) {
	return axios({
		method: 'post',
		url: Api.scanDetail,
		data: params
	})
}
// 签到/签退
export function singInOutMb(params) {
	return axios({
		method: 'post',
		url: Api.singInOut,
		data: params
	})
}
// 学生信息
export function studentInfoMb(id, params) {
	return axios({
		method: 'get',
		url: Api.studentInfo + id,
		params: params
	})
}
// 删除学生简历
export function deleteResumeMb(params) {
	return axios({
		method: 'get',
		url: Api.deleteResume,
		params: params
	})
}
// 删除学生课表
export function deleteScheduleMb(params) {
	return axios({
		method: 'get',
		url: Api.deleteSchedule,
		params: params
	})
}