<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="mine">
		<div class="mine-bg"></div>
		<div class="mine-inner">
			<div @click="handlerFn('personal')" class="mine-top">
				<div class="flex-item">
					<img v-if="userInfo.avatar" class="mine-top-avatar" :src="userInfo.avatar" alt="">
					<img v-else class="mine-top-avatar" src="@/assets/images/mobile/common/avatar.png" alt="">
					<div class="mine-top-content">
						<span class="mine-top-name">{{userInfo.userType == 'STUDENT'?userInfo.name:userInfo.account}}</span>
						<span class="mine-top-num">{{userInfo.userType == 'STUDENT'?`学号：${userInfo.account}`:`学校：浙江工业大学`}}</span>
					</div>
				</div>
				<img class="mine-top-arrow" src="@/assets/images/mobile/common/arrow-right.png" alt="">
			</div>
			<div v-if="identityMb == '学生'" class="mine-middle">
				<div class="mine-middle-title">
					我的岗位
				</div>
				<div class="mine-middle-list">
					<div @click="handlerFn(item.type)" v-for="(item, index) in statusList" :key="index"
						class="middle-list-item">
						<img :src="item.url" alt="">
						<span>{{item.title}}</span>
					</div>
				</div>
			</div>
			<div class="mine-bottom">
				<div class="mine-bottom-list">
					<div @click="handlerFn(item.type)" v-for="(item, index) in menuList" :key="index"
						class="bottom-list-item">
						<div class="flex-item">
							<img class="bottom-list-icon" :src="item.url" alt="">
							<span class="bottom-list-title">{{item.title}}</span>
						</div>
						<div class="flex-item">
							<span v-if="item.title == '消息通知'&&unReadNumber > 0"
								class="bottom-list-mark">{{unReadNumber}}</span>
							<img class="bottom-list-arrow" src="@/assets/images/mobile/common/arrow-right.png" alt="">
						</div>
					</div>
				</div>
				<div @click="handlerFn('logout')" class="mine-bottom-btn">
					重新认证
				</div>
				<!-- <tooltip @confirm="confirm" @cancle="cancle" :isShowToolTip="isShowToolTip" :tooltipTitle="'温馨提示'"
					:tooltipContent="'确定要退出该登录的账号吗？'" /> -->
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Toast
	} from 'vant';
	import storage from 'store'
	import {
		messageUnreadNumMb,
	} from "@/api/mobile/mine"
	import {
		getConfigCode
	} from "@/api/mobile/user";
	export default {
		data() {
			return {
				statusList: [{
						url: require("@/assets/images/mobile/mine/icon1.png"),
						title: "面试中",
						type: "objMs"
					},
					{
						url: require("@/assets/images/mobile/mine/icon2.png"),
						title: "已录用",
						type: "objLq"
					},
					{
						url: require("@/assets/images/mobile/mine/icon3.png"),
						title: "未录用",
						type: "objMl"
					},
					{
						url: require("@/assets/images/mobile/mine/icon4.png"),
						title: "已取消",
						type: "objQx"
					},
				],
				menuList: [],
				isShowToolTip: false,
				identityMb: "",
				userInfo: {},
				unReadNumber: 0
			}
		},
		mounted() {
			if (storage.get("identityMb")) {
				this.identityMb = storage.get("identityMb")
				switch (this.identityMb) {
					case "学生":
						this.menuList = [{
								url: require("@/assets/images/mobile/mine/icon5.png"),
								title: "我的课表",
								type: "myClass"
							},
							{
								url: require("@/assets/images/mobile/mine/icon9.png"),
								title: "简历记录",
								type: "resume"
							},
							{
								url: require("@/assets/images/mobile/mine/icon6.png"),
								title: "消息通知",
								type: "inform"
							},
						]
						this.getMessageUnreadNum()
						break;
					case "学院老师":
						this.menuList = [
							// {
							// 	url: require("@/assets/images/mobile/mine/icon8.png"),
							// 	title: "扫码签到/签退",
							// 	type: "readCode"
							// }
							// {
							// 	url: require("@/assets/images/mobile/mine/icon7.png"),
							// 	title: "修改密码",
							// 	type: "editPwd"
							// }
						]
						break;
					case "学校老师":
						this.menuList = [
							{
								url: require("@/assets/images/mobile/mine/icon6.png"),
								title: "消息通知",
								type: "inform"
							}
							// {
							// 	url: require("@/assets/images/mobile/mine/icon8.png"),
							// 	title: "扫码签到/签退",
							// 	type: "readCode"
							// }
							// {
							// 	url: require("@/assets/images/mobile/mine/icon7.png"),
							// 	title: "修改密码",
							// 	type: "editPwd"
							// }
						]
						this.getMessageUnreadNum()
						break;
				}
			}
			this.userInfo = storage.get("accessInfo")
			// if (this.$dd.env.platform !== "notInDingTalk") {
			// 	this.$dd.ready(() => {})
			// }
		},
		methods: {
			// 获取消息未读数
			getMessageUnreadNum() {
				messageUnreadNumMb().then((res) => {
					if (res.code == 0) {
						this.unReadNumber = res.data * 1
					}
				})
			},
			// confirm() {
			// 	this.$store.dispatch("mbLogout").then(() => {
			// 		this.isShowToolTip = false
			// 		this.$router.push({
			// 			path: `/mb/identity`
			// 		})
			// 	});
			// },
			cancle() {
				this.isShowToolTip = false
			},
			handlerFn(type) {
				let that = this
				switch (type) {
					case "personal":
						that.$router.push({
							path: `/mb/mine/personal`
						})
						break;
					case "logout":
						this.$store.dispatch("mbLogout").then(() => {
							// that.$router.push({
							// 	path: `/mb/user/login`
							// })
							//正式环境放出
							getConfigCode().then(res=>{
								if(res.code == 0){
									var pathUrl= "http://www.57qingong.zjut.edu.cn/#/mb/activity/index"
									const uri = encodeURIComponent(pathUrl)
									const REDIRECT_URI =res.data.baseUrl+'/cas/oauth2.0/authorize?response_type=code&client_id='+res.data.appKey+'&redirect_uri='+uri
									location.href = REDIRECT_URI
								}
							})
						});
						break;
					case "objMs":
						that.$router.push({
							path: `/mb/activity/applyed?visitType=interwiew`
						})
						break;
					case "objLq":
						that.$router.push({
							path: `/mb/activity/applyed?visitType=employed`
						})
						break;
					case "objMl":
						that.$router.push({
							path: `/mb/activity/applyed?visitType=unemploy`
						})
						break;
					case "objQx":
						that.$router.push({
							path: `/mb/activity/applyed?visitType=canceled`
						})
						break;
					case "myClass":
						that.$router.push({
							path: `/mb/mine/schedule`
						})
						break;
					case "resume":
						that.$router.push({
							path: `/mb/mine/resumehistory`
						})
						// that.$router.push({
						// 	path: `/mb/mine/resume`
						// })
						break;
					case "inform":
						that.$router.push({
							path: `/mb/mine/inform`
						})
						break;
					case "editPwd":
						that.$router.push({
							path: `/mb/mine/password`
						})
						break;
					case "readCode":
						if (that.$dd.env.platform !== "notInDingTalk") {
							that.$dd.ready(() => {
								that.$dd.biz.util.scan({
									type: "all", // type 为 all、qrCode、barCode，默认是all。
									onSuccess: function(res) {
										console.log("扫码成功", res.text)
										if (res.text) {
											Toast.success({
												message: "扫码成功"
											})
											setTimeout(() => {
												that.$router.push({
													path: `/mb/mine/singin?scanResult=${res.text}`
												})
											}, 1000)
										}
									},
									onFail: function(err) {
										console.log("扫码失败", err)
									}
								})
							})
						}
						break;
				}
			}
		},
	}
</script>
<style lang="scss" scoped>
	.flex-item {
		display: flex;
		align-items: center;
	}

	.mine {
		position: relative;

		.mine-bg {
			width: 100vw;
			height: 4.3rem;
			background: linear-gradient(180deg, #FFFFFF 0%, #F7F8F9 100%);
		}

		.mine-inner {
			position: absolute;
			top: 0;
			left: 0.28rem;
			.mine-top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.44rem 0.12rem;

				.mine-top-avatar {
					width: 1.28rem;
					height: 1.28rem;
					border-radius: 50%;
					margin-right: 0.32rem;
					background: #FAF4EC;
					border: 0.04rem solid #FFFFFF;
				}

				.mine-top-content {
					display: flex;
					flex-direction: column;

					.mine-top-name {
						font-size: 0.4rem;
						font-family: PingFang SC;
						font-weight: bold;
						line-height: 0.56rem;
						color: #303133;
						margin-bottom: 0.08rem;
					}

					.mine-top-num {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
					}
				}

				.mine-top-arrow {
					width: 0.32rem;
					height: 0.32rem;
				}
			}

			.mine-middle {
				width: 6.94rem;
				display: flex;
				flex-direction: column;
				padding: 0.2rem 0.28rem;
				background: #FFFFFF;
				box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
				border-radius: 0.12rem;
				margin: 0 auto;
				box-sizing: border-box;

				.mine-middle-title {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: bold;
					line-height: 0.4rem;
					color: #303133;
				}

				.mine-middle-list {
					display: flex;
					align-items: center;
					justify-content: space-around;
					margin-top: 0.2rem;

					.middle-list-item {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						img {
							width: 0.64rem;
							height: 0.64rem;
							margin-bottom: 0.16rem;
						}

						span {
							ont-size: 0.24rem;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 0.34rem;
							color: #303133;
						}
					}
				}
			}

			.mine-bottom {
				display: flex;
				flex-direction: column;

				.mine-bottom-list {
					display: flex;
					flex-direction: column;

					.bottom-list-item {
						width: 6.94rem;
						display: flex;
						align-items: center;
						justify-content: space-between;
						background: #FFFFFF;
						box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
						border-radius: 0.12rem;
						padding: 0.28rem 0.28rem;
						box-sizing: border-box;
						margin: 0.16rem auto 0 auto;

						.bottom-list-icon {
							width: 0.4rem;
							height: 0.4rem;
							margin-right: 0.16rem;
						}

						.bottom-list-title {
							font-size: 0.28rem;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 0.4rem;
							color: #303133;
						}

						.bottom-list-mark {
							min-width: 0.32rem;
							height: 0.32rem;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #E82D2D;
							border-radius: 50%;
							font-size: 0.24rem;
							font-family: PingFang SC;
							font-weight: 400;
							color: #FFFFFF;
						}

						.bottom-list-arrow {
							width: 0.32rem;
							height: 0.32rem;
						}
					}
				}

				.mine-bottom-btn {
					width: 6.94rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #FFFFFF;
					box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
					border-radius: 0.12rem;
					padding: 0.28rem 0.28rem;
					box-sizing: border-box;
					margin: 0.16rem auto 0 auto;
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #909399;
				}
			}
		}
	}
</style>
